import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { BootstrapVue } from 'bootstrap-vue'
import Scrollspy from 'vue2-scrollspy';
import VueYoutube from 'vue-youtube'

var VueScrollTo = require('vue-scrollto');

import VueParticles from 'vue-particles'

Vue.config.productionTip = false

Vue.use(VueYoutube)
Vue.use(VueParticles)

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(VueScrollTo)
Vue.use(Scrollspy);

import "@/assets/scss/style.scss";

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

var query = router.currentRoute.query;
import mixpanel from "mixpanel-browser/src/loader-module";
mixpanel.init('6f67c0b89156b9f30599cc233ba283e5');
mixpanel.register({
  "캠페인 명": query.utm_campaign,
  "캠페인 매체": query.utm_medium,
  "캠페인 채널": query.utm_channel,
  "캠페인 내용": query.utm_content,
});
mixpanel.track("랜딩페이지 접속")
